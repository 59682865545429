/**
 * This composable is used to manage all the states for the svg map in home, OEQP etc...
 * It uses two main composables: useFilters and useFetchFilters
 * useFilters is used to manage the filters state and the filters modal locally only
 * useFetchFilters is used to fetch the results from the backend
 *
 * Around these two composables, we have watchers and computed properties to manage the states
 *
 * We return functions and states to be used in the following components:
 * - SectionMapFilters
 * - FiltersModal
 * - SectionGridResults
 *
 * Note: this composable is used only to filter products
 * You can pass either product, article or event to the useFetchFilters composable.
 * For the SectionMapFilters, we pass nothing, which means that we will only filter products as it is the default value
 */
import { storeToRefs } from 'pinia';

import {
  COUNTRIES_ALPHA_2,
  FILTERS_ORDERING,
  IsoCode,
  typeAndWishCategoriesFilters
} from '@/lib/variables';

import { Journey } from '@/lib/strapi-types/Journey';

import { Filter, FilterDefinition, FiltersObject } from '@/lib/types/filters';
import { CountryByJourney, ResultType, SlugItem } from '@/lib/types/models/common';
import { JourneyModel } from '@/lib/types/models/journey';
import parseJourney from '@/lib/types/parsers/parseJourney';
import { PaginationByOffset, Strapi4ResponseMany } from '@/lib/types/strapi';

export default async function useSectionMapFilters(
  initialFiltersObject?: Ref<FiltersObject>,
  isCategoryPage: boolean = false
): Promise<{
  periodsFilters: ComputedRef<FilterDefinition<'seasons'>[]>;
  travelStyleFilters: FilterDefinition<'typeCategories'>[];
  onPageChange: () => void;
  isModalOpened: Ref<boolean>;
  isShowMobileMap: Ref<boolean>;
  handleToggleModal: (isOpen: boolean) => void;
  handleSetFilters: (newFilters: FiltersObject) => void;
  handleResetFilters: () => void;
  handleDeleteFilter: (filter: Filter) => void;
  handleToggleShowMobileMap: () => void;
  filters: Ref<Filter[]>;
  filtersObject: Ref<FiltersObject>;
  mainResultName: ComputedRef<string>;
  countriesByJourneys: Ref<CountryByJourney[]>;
  resultsResponse: Ref<Strapi4ResponseMany<ResultType> | undefined>;
  isLoadingResults: Ref<boolean>;
  isLoadingPagination: Ref<boolean>;
  handlePaginate: () => void;
  sort: Ref<string[]>;
  resultsProductModel: ComputedRef<JourneyModel[] | undefined>;
  updateSort: (e: string) => void;
  setNewDestination: (isoCode: IsoCode) => void;
  pagination: Ref<PaginationByOffset>;
}> {
  // Global State to fetch filters from backend: travelStyles, seasons, destinations...
  const globalDataStore = useGlobalFetchedDataStore();
  const { getPeriodsAsFilters } = storeToRefs(globalDataStore);

  // Default pagination
  const defaultPagination = { start: 0, limit: 8, withCount: true };

  const pagination = ref<PaginationByOffset>(defaultPagination);

  const limitFactor = ref(1);

  // Change pagination
  function onPageChange() {
    limitFactor.value += 1;
  }

  const sort = ref<string[]>([]);

  const {
    filters,
    filtersObject,
    isModalOpened,
    isShowMobileMap,
    handleToggleModal,
    handleSetFilters,
    handleResetFilters,
    handleDeleteFilter,
    handleToggleShowMobileMap
  } = useFilters(
    initialFiltersObject?.value ?? {
      destinations: [],
      seasons: [],
      typeCategories: [],
      budgets: [],
      durations: [],
      categories: []
    }
  );

  const { resultsResponse, isLoadingResults, isLoadingPagination, handlePaginate } =
    await useFetchFilters(
      filtersObject,
      'gridResults',
      pagination,
      sort,
      'journey',
      isCategoryPage
    );

  // Label to show when filters are applied
  const mainResultName = computed(() => {
    if (filtersObject.value.destination) {
      return (filtersObject.value.destination as Filter<SlugItem>).value.name;
    }
    return '';
  });

  // Countries by journeys to show on the map
  const countriesByJourneys = ref<CountryByJourney[]>([]);

  // Parsed Results to show on the grid
  const resultsProductModel = computed(() =>
    resultsResponse.value?.data.map((journey: any) => parseJourney(journey as Journey))
  );

  // Used to update the destination object when we have a searchbar in the first step
  function setNewDestination(isoCode: IsoCode) {
    // Check if the ISO code already exists in the destinations list
    const destinations = filtersObject.value.destinations as Filter<SlugItem>[];

    const isExisting = destinations.some((item: any) => item.value.code === isoCode);

    if (isExisting) {
      const updatedDestinations = destinations.filter((item: any) => item.value.code !== isoCode);

      handleSetFilters({
        ...filtersObject.value,
        destinations: updatedDestinations
      });
    } else {
      const newDestination: Filter<SlugItem> = {
        text: COUNTRIES_ALPHA_2[isoCode],
        identifier: 'destination',
        value: {
          name: COUNTRIES_ALPHA_2[isoCode],
          slug: isoCode,
          code: isoCode,
          type: 'country'
        }
      };
      destinations.push(newDestination);
      handleSetFilters({ ...filtersObject.value, destinations });
    }
  }

  function updateSort(filterValue: string) {
    // Reset sort filters
    delete filtersObject.value.bestSeller;
    delete filtersObject.value.priceAsc;
    delete filtersObject.value.priceDesc;
    delete filtersObject.value.daysAsc;
    delete filtersObject.value.daysDesc;

    const sortFilter = FILTERS_ORDERING.find(filter => filter.value === filterValue);

    if (!sortFilter) {
      sort.value = [];
      return;
    }

    const createFilter = (
      identifier: string,
      label: string,
      value: string | boolean,
      description?: string
    ) => ({
      text: description ? `${label} ${description}` : label,
      identifier,
      value
    });

    switch (filterValue) {
      case 'best_sellers':
        filtersObject.value.bestSeller = createFilter('bestSeller', sortFilter.label, true);
        break;

      case 'price:asc':
        filtersObject.value.priceAsc = createFilter(
          'priceAsc',
          sortFilter.label,
          sortFilter.value,
          sortFilter.description
        );
        sort.value = [filterValue];
        break;

      case 'price:desc':
        filtersObject.value.priceDesc = createFilter(
          'priceDesc',
          sortFilter.label,
          sortFilter.value,
          sortFilter.description
        );
        sort.value = [filterValue];
        break;

      case 'days:asc':
        filtersObject.value.daysAsc = createFilter(
          'daysAsc',
          sortFilter.label,
          sortFilter.value,
          sortFilter.description
        );
        sort.value = [filterValue];
        break;

      case 'days:desc':
        filtersObject.value.daysDesc = createFilter(
          'daysDesc',
          sortFilter.label,
          sortFilter.value,
          sortFilter.description
        );
        sort.value = [filterValue];
        break;

      default:
        sort.value = [];
        break;
    }

    handleSetFilters({ ...filtersObject.value });
  }

  watch(filters, () => {
    if (filters.value.length === 0) {
      countriesByJourneys.value = [];
      sort.value = [];
    }
  });

  watch(limitFactor, () => {
    pagination.value = { ...pagination.value, limit: limitFactor.value * pagination.value.limit };

    handlePaginate();
  });

  watchEffect(() => {
    if (initialFiltersObject) handleSetFilters(initialFiltersObject.value);
  });

  return {
    periodsFilters: getPeriodsAsFilters,
    travelStyleFilters: typeAndWishCategoriesFilters,
    onPageChange,
    isModalOpened,
    isShowMobileMap,
    handleToggleModal,
    handleSetFilters,
    handleResetFilters,
    handleDeleteFilter,
    handleToggleShowMobileMap,
    filters,
    filtersObject,
    mainResultName,
    countriesByJourneys,
    resultsResponse,
    isLoadingResults,
    isLoadingPagination,
    handlePaginate,
    sort,
    resultsProductModel,
    updateSort,
    setNewDestination,
    pagination
  };
}
